import {
  type CSSProperties,
  type FC,
  useCallback,
  useContext,
  useEffect,
} from 'react';

import { ImagePreview } from '@flow-infra/bot-semi';

import { clientCssVars } from '@/util/style';
import {
  type PostMessage,
  PostMessageEvent,
  postMessageUtil,
} from '@/types/post';
import { Layout } from '@/types/client';
import {
  GlobalStoreContext,
  GlobalStoreProvider,
  useGlobalStore,
  useIframeStore,
} from '@/store/context';
import { clientLogger } from '@/log';
import { CozeWidgetMobile } from '@/components/coze-client-widget/mobile';
import { type WebChatClient } from '@/client';

import { CozeIframe } from '../coze-iframe';
import WidgetSvg from '../../assets/widget.svg';

import styles from './index.module.less';

export interface CozeWidgetProps {
  position?: 'static' | 'fixed';
  style?: CSSProperties;
  className?: string;
  client: WebChatClient;
}

const IFRAME_INDEX = 2;

const CozeWidgetMain: FC<CozeWidgetProps> = ({
  position = 'fixed',
  client,
}) => {
  const { globalStore, iframeStore } = useContext(GlobalStoreContext);
  const {
    chatVisible,
    setChatVisible,
    imagePreviewVisible,
    iframeLoaded,
    imagePreviewUrl,
    setImagePreview,
  } = useGlobalStore(s => ({
    chatVisible: s.chatVisible,
    setChatVisible: s.setChatVisible,
    iframeLoaded: s.iframeLoaded,
    imagePreviewVisible: s.imagePreview.visible,
    imagePreviewUrl: s.imagePreview.url,
    setImagePreview: s.setImagePreview,
  }));
  const componentProps = client?.options?.componentProps;
  const iconUrl = componentProps?.icon;
  const zIndex = componentProps?.zIndex;

  useEffect(() => {
    if (!iframeStore) {
      return;
    }

    const unsubscribe = iframeStore.subscribe(
      state => ({ lang: state.lang }),
      state => {
        globalStore.getState()?.iframe.contentWindow.postMessage(state);
      },
    );

    return () => {
      unsubscribe?.();
    };
  }, []);

  const onMessageHandler = useCallback<{
    (event: MessageEvent<PostMessage>): void;
  }>(
    event => {
      const msg = event?.data;

      if (msg.chatStoreId !== client.chatClientId) {
        return;
      }

      clientLogger.log('window.on("message")', {
        msg,
      });

      switch (msg.event) {
        case PostMessageEvent.ImageClick:
          if (postMessageUtil.isImageClick(msg)) {
            setImagePreview(preview => {
              preview.url = msg.payload.url;
              preview.visible = true;
            });
          }
          break;
        default:
      }
    },
    [setImagePreview, client],
  );

  useEffect(() => {
    window.addEventListener('message', onMessageHandler);
    return () => {
      window.removeEventListener('message', onMessageHandler);
    };
  }, [onMessageHandler]);

  const showIframe = chatVisible || iframeLoaded;

  const cssVars = {
    ...clientCssVars,
    ...(typeof zIndex === 'number'
      ? {
          '--coze-z-index-iframe': zIndex,
        }
      : {}),
  };

  return (
    <>
      {showIframe && (
        <CozeIframe
          style={{
            display: chatVisible ? 'block' : 'none',
            ...cssVars,
          }}
          onClose={() => setChatVisible(false)}
          iframeParamsSearch={client.iframeParamsSearch}
        />
      )}
      <ImagePreview
        zIndex={cssVars['--coze-z-index-iframe'] + IFRAME_INDEX}
        src={imagePreviewUrl}
        visible={imagePreviewVisible}
        onClose={() => {
          setImagePreview(preview => (preview.visible = false));
        }}
      />
      {!chatVisible && (
        <div
          style={{ position, ...cssVars }}
          className={styles.cozeChatWidget}
          onClick={e => {
            e.stopPropagation();
            setChatVisible(true);
          }}
        >
          <img alt="logo" src={iconUrl || WidgetSvg} />
        </div>
      )}
    </>
  );
};

const WidgetAdapter: FC<CozeWidgetProps> = props => {
  const { layout } = useIframeStore(s => ({ layout: s.layout }));

  if (layout === Layout.MOBILE) {
    return <CozeWidgetMobile {...props} />;
  }

  return <CozeWidgetMain {...props} />;
};

const CozeClientWidget: FC<CozeWidgetProps> = props => (
  <GlobalStoreProvider client={props.client}>
    <WidgetAdapter {...props} />
  </GlobalStoreProvider>
);

export default CozeClientWidget;

import { I18n } from '@flow-infra/i18n/raw';

export enum SDKErrorCode {
  Base = 1000,
  OpenApiUpload = 1001,
  NoClearAPI = 1002,
  Iframe = 2000,
  IframeParams = 2001,
  Core = 3000,
  ACK = 3001,
  NotError = 4000,
  Unknow = 5000,
}

export class ChatSdkError extends Error {
  sdkCode: SDKErrorCode;

  constructor(options: { sdkCode: SDKErrorCode }) {
    super();
    if (typeof options === 'number') {
      this.sdkCode = options;
    } else {
      this.sdkCode = options.sdkCode;
    }
  }

  static wrap(err: Error, code = SDKErrorCode.Base) {
    const newErr = new ChatSdkError({ sdkCode: code });
    newErr.message = err.message;
    newErr.name = err.name;
    newErr.cause = err.cause;
    newErr.stack = err.stack;

    return newErr;
  }

  static create(sdkCode: SDKErrorCode) {
    return new ChatSdkError({ sdkCode });
  }
}

export interface SDKInitError {
  code: number;
  message: string;
}

enum ServerErrorCode {
  BotUnbind = 702242003,
}

export const getServerError = (code: ServerErrorCode) => {
  switch (code) {
    case ServerErrorCode.BotUnbind:
      return {
        code,
        message: I18n.t('unbind_notification'),
      };
    default:
  }
};

/**
 * 依赖 treeShaking 去除无关配置(Argus)
 */
export const iframeAppHost = (() => {
  if (IS_BOE) {
    return 'https://bot-studio-sdk-inhouse.gf-boe.bytedance.net';
  }

  if (IS_RELEASE_VERSION) {
    switch (REGION) {
      case 'sg':
      case 'va':
        return 'https://api.coze.com';
      case 'cn':
        return 'https://api.coze.cn';
      default:
        return '';
    }
  }

  switch (REGION) {
    case 'sg':
      return 'https://bot-studio-sdk-inhouse.gf-sg.tiktok-row.net';
    case 'cn':
      return 'https://bot-studio-sdk-inhouse.gf.bytedance.net';
    default:
      return '';
  }
})();

export const cozeOfficialHost = (() => {
  if (IS_BOE) {
    return 'https://bots-boe.bytedance.net';
  }

  if (IS_RELEASE_VERSION) {
    switch (REGION) {
      case 'va':
      case 'sg':
        return 'https://www.coze.com';
      case 'cn':
        return 'https://www.coze.cn';
      default:
        return '';
    }
  }

  switch (REGION) {
    case 'sg':
      return 'https://bots.byteintl.net';
    case 'cn':
      return 'https://bots.bytedance.net';
    default:
      return '';
  }
})();

export const openApiHostByRegion = (() => {
  if (IS_BOE) {
    return 'https://bots-boe.bytedance.net';
  }

  if (IS_RELEASE_VERSION) {
    switch (REGION) {
      case 'sg':
      case 'va':
        return 'https://api.coze.com';
      case 'cn':
        return 'https://api.coze.cn';
      default:
        return '';
    }
  }

  switch (REGION) {
    case 'sg':
      return 'https://bots.byteintl.net';
    case 'cn':
      return 'https://bots.bytedance.net';
    default:
      return '';
  }
})();

export const cozeHostTitle = (() => {
  switch (REGION) {
    case 'sg':
    case 'va':
      return 'Coze.com';
    case 'cn':
      return 'Coze.cn';
    default:
      return '';
  }
})();

const openSdkPrefix = (() => {
  if (!IS_RELEASE_VERSION) {
    return 'https://sf-unpkg-src.bytedance.net';
  }

  switch (REGION) {
    case 'cn':
      return 'https://lf-cdn.coze.cn/obj/unpkg';
    case 'va':
    case 'sg':
      return 'https://sf-cdn.coze.com/obj/unpkg-va';
    default:
      return '';
  }
})();

const sdkRegion = (() => {
  if (IS_BOE) {
    return 'boe';
  }

  if (!IS_RELEASE_VERSION || REGION === 'cn') {
    return REGION;
  }

  return 'oversea';
})();

export const getOpenSDKUrl = (version: string) =>
  IS_RELEASE_VERSION
    ? `${openSdkPrefix}/flow-platform/chat-app-sdk/${version}/libs/${sdkRegion}/index.js`
    : `${openSdkPrefix}/@flow-platform/chat-app-sdk@${version}/inhouse/${sdkRegion}/index.js`;

export const getOpenSDKPath = (version: string) =>
  IS_RELEASE_VERSION
    ? `/${
        REGION === 'cn' ? 'obj/unpkg' : 'obj/unpkg-va'
      }/flow-platform/chat-app-sdk/${version}/libs/${sdkRegion}/index.js`
    : `/@flow-platform/chat-app-sdk@${version}/inhouse/${sdkRegion}/index.js`;

export const isDevAlias = IS_DEV_MODE;
